import { app } from 'hyperapp'

import Main from './Main'

const devtools =
  process.env.NODE_ENV !== 'production'
    ? require('hyperapp-redux-devtools')
    : null

const state = {
  principal: null,
  rate: null,
  years: null,
  increment: 1,
}

const actions = {
  handleEnter: ev => (state, { set }) => { ev.keyCode === 13 && ev.target.blur() && set(ev) },
  handleEnterWithoutDelimiter: ev => (state, { set }) => { ev.keyCode === 13 && ev.target.blur() && setWithoutDelimiter(ev) },
  set: ({ target }) => () => ({ [target.name]: target.value }),
  setWithoutDelimiter: ({ target }) => () => ({ [target.name]: parseInt(target.value.toString().replace(/[,\.]/g, '')) }),
  dec: () => ({ increment }) => ({ increment: increment - 1 }),
  inc: () => ({ increment }) => ({ increment: increment + 1 }),
}

if (devtools) {
  devtools(app)(state, actions, Main, document.body)
} else {
  app(state, actions, Main, document.body)
}