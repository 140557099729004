import { h } from 'hyperapp'
import cc from 'classcat'

export default ({ active }) => ({ increment, principal, rate, years }, { handleEnter, handleEnterWithoutDelimiter, set, setWithoutDelimiter, inc, dec }) => (
  <div class="mt-auto px-8 bg-white">
    <div class="flex flex-wrap pt-4">
      <div class="w-full">
        <input class="w-full pt-4 pb-2 text-lg tracking-wide border-b-2 border-grey rounded-none outline-none" name="principal" type="number" min="0" placeholder="AMOUNT LEFT" value={principal} onblur={setWithoutDelimiter} onkeyup={handleEnterWithoutDelimiter}/>
      </div>
      <div class="w-3/5 pr-8">
        <input class="w-full pt-4 pb-2 text-lg tracking-wide border-b-2 border-grey rounded-none outline-none" name="years" type="number" min="0" placeholder="YEARS LEFT" value={years} onblur={setWithoutDelimiter} onkeyup={handleEnterWithoutDelimiter}/>
      </div>
      <div class="w-2/5">
        <input class="w-full pt-4 pb-2 text-lg tracking-wide border-b-2 border-grey rounded-none outline-none" name="rate" type="number" min="0" placeholder="RATE" value={rate} onblur={set} onkeyup={handleEnter}/>
      </div>
    </div>

    <div class="flex mt-4">
      <div class="flex mr-2 text-5xl text-black font-cursive">
        <small class="pt-2 text-3xl">$</small>{increment}
      </div>
      <div class="max-w-2xs pt-3 leading-normal tracking-wide uppercase">
        Get a new lease on life with a few <span class="block text-black">extra $ / day</span>
      </div>
      <div class="flex flex-col ml-auto pt-2">
        <button class="w-12 outline-none" onclick={inc}>
          <svg
            viewBox="0 0 78 66"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            class={cc({
              'text-black': !active,
              'text-green': active,
            })}>
            <clipPath id="a"><path d="M0 0h78v66H0z"/></clipPath>
            <g clip-path="url(#a)">
              <path d="M0 66c0-10.24.53-23.94 1.6-31.59 1.06-7.64 3.08-13.88 6.04-18.7 3.43-5.3 7.76-9.18 12.97-11.65C26.06 1.47 33.81.12 43.9 0 56.92 1.88 65.5 6.47 69.65 13.77a56.71 56.71 0 0 1 7.28 23.46A339.6 339.6 0 0 1 78 66H0z"/><path d="M42.2 28.92c0 .37.05.66.13.87 0 .12.22.2.66.21l.9.03h2.43a50.5 50.5 0 0 1 6.18-.24c.75 0 1.2.16 1.37.5.17.32.25.74.25 1.23v3.22c0 .91-.54 1.37-1.62 1.37h-9.3c-.46.16-.68.58-.68 1.24l.06 1.55.06 4.02c.04.3.06 1.04.06 2.24.04.7.07 1.48.07 2.35 0 .91-.1 1.57-.32 1.98-.2.42-.52.69-.93.81-.3 0-.9-.04-1.81-.12-.92-.09-1.62-.13-2.12-.13-.63 0-1.02-.2-1.19-.59-.17-.39-.25-.92-.25-1.58V37.41c0-.79-.48-1.18-1.43-1.18-.46 0-.96.06-1.5.19l-3.06.12-3.87.13c-.5 0-.8-.08-.93-.22-.13-.15-.2-.49-.2-1.02-.07-.46-.16-1.02-.24-1.68l-.25-1.6c-.08-.8 0-1.38.25-1.78.25-.39.58-.58 1-.58l3-.07c.78 0 1.34.02 1.68.07.54-.05 1.22-.02 2.06.06l1.24.06a5.2 5.2 0 0 0 1.32-.12c.41-.09.67-.3.78-.65.1-.36.14-.81.12-1.37a34.5 34.5 0 0 1-.03-1.27v-4.83c-.08-.42-.13-1.14-.13-2.17 0-1.94.15-3.12.44-3.54.37-.12.73-.18 1.06-.18.21-.04 1.25-.06 3.12-.06.67-.09 1.08.12 1.25.62.17.5.24.99.22 1.48l-.03.93.06 2.85c.08 1.41.12 2.28.12 2.6l.07 2.73c0 .58-.02 1.06-.07 1.43v.56z" fill="#fff"/>
            </g>
          </svg>
        </button>
        <button class="w-12 -mt-2 outline-none" disabled={increment <= 1 } onclick={dec}>
          <svg
            viewBox="0 0 78 66"
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            class={cc({
              'text-black': active,
              'text-red': !active,
            })}>
            <clipPath id="a"><path d="M0 0h78v66H0z"/></clipPath>
            <g clip-path="url(#a)">
              <path d="M78 4.82c0 11.41-.77 21.58-2.31 30.5-1.42 9.4-5.36 16.86-11.81 22.39C57.41 63.22 49.03 66 38.73 66c-11.01-.6-18-1.66-20.96-3.21-2.96-1.54-6.1-5.35-9.42-11.42A72.01 72.01 0 0 1 1.6 34.43C.53 29.19 0 17.73 0 0l78 4.82z"/><path d="M39.8 35.33h-3.4c-.52 0-1.65.05-3.41.14-1.4.09-2.44.14-3.13.14l-4.1.28c-1.02 0-1.53-.42-1.53-1.26l.06-.9v-2.02c0-1.02.68-1.53 2.02-1.53h7.65l13.7-.06h2.84c.98 0 1.72.02 2.23.06.74 0 1.23.14 1.46.42.23.28.35.65.35 1.11v1.36c0 .76-.05 1.45-.14 2.05-.14.32-.44.49-.9.49-.24 0-.73-.07-1.47-.21-.74-.14-1.34-.21-1.8-.21-2.97.1-6.45.14-10.43.14" fill="#fff"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
)