import { h } from 'hyperapp'
import cc from 'classcat'

import { daysToText, Remortgage } from './utils'
import Calculator from './Calculator'

export default ({ increment, principal, rate, years }) => {
  const active = principal && rate && years
  const remortgage = new Remortgage(principal, rate, years * 12, increment)

  return (
    <div class="flex flex-col sm:block h-full">
      <div class="flex flex-grow sm:items-end sm:min-h-half sm:bg-light">
        <header class={cc([
            'flex flex-col sm:max-w-sm mx-auto p-8',
            {
              'bg-red': !active,
              'bg-green': active,
            }
          ])}>
          <h1 class="text-4xl text-white font-cursive leading-tight uppercase">
            {
              active
                ? <span>You just saved… <b class="block text-black font-normal">{daysToText(remortgage.daysSaved())}.</b></span>
                : <span>Mortgage is french for <b class="text-black font-normal">dead pledges.</b></span>
            }
          </h1>
          {
            active && <h2 class="mt-auto pt-4 text-lg text-black font-normal tracking-wide uppercase">and ${remortgage.interestSaved()} in interest.</h2>
          }
        </header>
      </div>
      <div class="flex flex-col sm:flex-grow sm:min-h-half sm:bg-dark">
        <div class="sm:max-w-sm mx-auto">
          <Calculator active={active} />
          <footer class="px-8 py-2 text-xs text-grey-dark text-right uppercase tracking-wide bg-black">
            An <a href="http://studio-two.com.au" class="text-grey no-underline" title="Visit Studio Two website">S2</a> gift to the world
          </footer>
        </div>
      </div>
    </div>
  )
}