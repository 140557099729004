export class Remortgage {
  constructor(principal, rate, months, dailyIncrement) {
    this.principal = principal
    this.rate = rate
    this.months = months
    this.increment = dailyIncrement
  }

  daysSaved() {
    return this.monthsSaved() * 30
  }

  interest() {
    return this.totalPayment() - this.principal
  }

  interestSaved() {
    return Math.floor(this.interest() - this.repaymentInterest())
  }

  monthlyRepayment() {
    return this.totalPayment() / this.months + this.increment * 30
  }

  monthsSaved() {
    return this.months - this.repaymentMonths()
  }

  ratePerMonth() {
    return this.rate / 1200
  }

  rateOverLife() {
    return Math.pow(1 + this.ratePerMonth(), this.months)
  }

  repaymentInterest() {
    return this.totalRepayment() - this.principal
  }

  repaymentMonths() {
    const x = this.monthlyRepayment() / this.principal
    const y = x * -1 / (this.ratePerMonth() - x)

    return (Math.log(y) / Math.log(1 + this.ratePerMonth()))
  }

  totalPayment() {
    return this.principal * this.months * (
      (this.ratePerMonth() * this.rateOverLife()) / (this.rateOverLife() - 1)
    )
  }

  totalRepayment() {
    return this.monthlyRepayment() * this.repaymentMonths()
  }
}

export const yearsText = Y => {
  const y = Math.floor(Y)
  return y > 0 ? `${y} ${y > 1 ? 'years' : 'year'}` : ''
}

export const monthsText = M => {
  const m = Math.floor(M)
  return m > 0 ? `${m} ${m > 1 ? 'months' : 'month'}` : ''
}

export const daysText = D => {
  const d = Math.floor(D)
  return d > 0 ? `& ${d} ${d > 1 ? 'days' : 'day'}` : ''
}


export const daysToText = days => {
  const Y = days / 365
  const M = Y % 1 * 12
  const D = M % 1 * 31

  return `${yearsText(Y)} ${monthsText(M)} ${daysText(D)}`
}